import styles from './loginLogos.module.scss';
import { UserIcon, CartIcon } from '@/components/organisms';

const LoginLogos = () => {
  return (
    <div className={styles.loginLogos}>
      <UserIcon />
      <CartIcon />
    </div>
  );
};

export default LoginLogos;
